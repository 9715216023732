import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/worker/code/src/templates/blog-post.js";
import { Link } from "gatsby";
import { IMPACT_URL, BLOG_HOME_URL } from "../constants/pageUrls";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <p>{`Welcome to the first of two stories we bring to you for this year's `}<a parentName="p" {...{
        "href": "https://www.usa.gov/flec"
      }}>{`Financial Capability Month`}</a>{`.`}</p>
    <p>{`Meet Don, a Direct Express cardholder & PayPerks user, who lives a life of service to those around him in his neighborhood.`}</p>
    <div className="player-frame"><iframe width="560" height="315" src="https://www.youtube.com/embed/1f14oKd9jx8" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></div>
    <p>{`We spent a wonderful afternoon with Don, talking about his card, the handywork he does, his life story, and how he manages his money smartly.`}</p>
    <p><em parentName="p">{`All of our user impact videos are available on our `}<Link to={IMPACT_URL} mdxType="Link">{`Impact`}</Link>{` page.`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      